import styled from 'styled-components';

const Link = styled.a`
  &:link {
    color: #96d8ff;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #96d8ff;
  }
`;

export default Link;
